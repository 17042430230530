[data-component='Peer'] {
  flex: 100 100 auto;
  position: relative;
  height: 100%;
  width: 100%;
}
@media (max-device-width: 720px) {
  [data-component='Peer'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
[data-component='Peer'] > .indicators {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 150px;
  display: flex;
flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
[data-component='Peer'] > .indicators > .icon {
  flex: 0 0 auto;
  margin: 4px;
  margin-left: 0;
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  transition-property: opacity;
  transition-duration: 0.15s;
}
@media (min-device-width: 721px) {
  [data-component='Peer'] > .indicators > .icon {
    opacity: 0.85;
  }
}
[data-component='Peer'] > .indicators > .icon.mic-off {
  background-image: url("./resources/images/icon_remote_mic_white_off.svg");
}
[data-component='Peer'] > .indicators > .icon.webcam-off {
  background-image: url("./resources/images/icon_remote_webcam_white_off.svg");
}
[data-component='Peer'] .incompatible-video {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
[data-component='Peer'] .incompatible-video > p {
  padding: 6px 12px;
  border-radius: 6px;
  user-select: none;
  pointer-events: none;
  font-size: 15px;
  color: rgba(255,255,255,0.55);
}
