[data-component="Me"] {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
[data-component="Me"] > .controls {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
}
[data-component="Me"] > .controls > .button {
  flex: 0 0 auto;
  margin: 4px;
  margin-left: 0;
  border-radius: 2px;
  pointer-events: auto;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition-property: opacity, background-color;
  transition-duration: 0.15s;
}
@media (min-device-width: 721px) {
  [data-component="Me"] > .controls > .button {
    width: 28px;
    height: 28px;
    opacity: 0.85;
  }
  [data-component="Me"] > .controls > .button:hover {
    opacity: 1;
  }
}
@media (max-device-width: 720px) {
  [data-component="Me"] > .controls > .button {
    width: 26px;
    height: 26px;
  }
}
[data-component="Me"] > .controls > .button.unsupported {
  pointer-events: none;
}
[data-component="Me"] > .controls > .button.disabled {
  pointer-events: none;
  opacity: 0.5;
}
[data-component="Me"] > .controls > .button.on {
  background-color: rgba(255, 255, 255, 0.85);
}
[data-component="Me"] > .controls > .button.mic.on {
  background-image: url("./resources/images/icon_mic_black_on.svg");
}
[data-component="Me"] > .controls > .button.mic.off {
  background-image: url("./resources/images/icon_mic_white_off.svg");
  background-color: rgba(212, 34, 65, 0.7);
}
[data-component="Me"] > .controls > .button.mic.unsupported {
  background-image: url("./resources/images/icon_mic_white_unsupported.svg");
}
[data-component="Me"] > .controls > .button.webcam.on {
  background-image: url("./resources/images/icon_webcam_black_on.svg");
}
[data-component="Me"] > .controls > .button.webcam.off {
  background-image: url("./resources/images/icon_webcam_white_on.svg");
}
[data-component="Me"] > .controls > .button.webcam.unsupported {
  background-image: url("./resources/images/icon_webcam_white_unsupported.svg");
}
[data-component="Me"] > .controls > .button.change-webcam.on {
  background-image: url("./resources/images/icon_change_webcam_black.svg");
}
[data-component="Me"] > .controls > .button.change-webcam.unsupported {
  background-image: url("./resources/images/icon_change_webcam_white_unsupported.svg");
}
[data-component="Me"] > .controls > .button.share.on {
  background-image: url("./resources/images/icon_share_black_on.svg");
}
[data-component="Me"] > .controls > .button.share.off {
  background-image: url("./resources/images/icon_share_white_on.svg");
}
[data-component="Me"] > .controls > .button.share.unsupported {
  background-image: url("./resources/images/icon_share_white_unsupported.svg");
}
