[data-component="Tabs"] {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50vw;
    padding: 10px;
    border-radius: 10px;
    background-color: #111316;
}

@media (max-device-width: 480px) {
  [data-component="Tabs"] {
    max-width: 55vw;
  }
}

[data-component="Tabs"] > .controls {
    display: flex;
    width: 31vw;
    flex-direction: row;
    align-items: center;
    pointer-events: none;
    justify-content: space-between;
}
[data-component="Tabs"] > .controls > .button {
    flex: 0 0 auto;
    margin: 5px;
    padding: 0px 10px;
    pointer-events: auto;
    background-position: center;
    background-size: 75%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition-property: opacity, background-color;
    transition-duration: 0.15s;
    display: grid;
    place-items: center;
  }

  @media (min-device-width: 12001px){
    [data-component="Tabs"] > .controls {
      width: 30vw;
    }
    [data-component="Tabs"] > .controls > .button {
      width: 37px;
      height: 30px;
      opacity: 0.85;
    }
  }
  @media (max-device-width: 480px){
  [data-component="Tabs"] > .controls {
    width: 70vw;
  }
  [data-component="Tabs"] > .controls > .button {
    width: 20px;
    height: 20px;
    opacity: 0.85;
  }
}

  @media (min-device-width: 769px) {
    [data-component="Tabs"] > .controls {
      width: 35vw;
    }
    [data-component="Tabs"] > .controls > .button {
      width: 30px;
      height: 25px;
      opacity: 0.85;
    }
    [data-component="Tabs"] > .controls > .button:hover {
      opacity: 1;
    }
  }
  @media (max-device-width: 768px) and (min-device-width: 481px) {
    [data-component="Tabs"] {
      width: 65vw;

    }
    [data-component="Tabs"] > .controls {
      width: 100%;
    }
    [data-component="Tabs"] > .controls > .button {
      width: 20px;
      height: 25px;
    }
  }

[data-component="Tabs"] > .controls > .button.mic.on {
    background-image: url("./icons/mic_on.svg");
}

[data-component="Tabs"] > .controls > .button.mic.off {
    background-image: url("./icons/mic_off.svg");
}

[data-component="Tabs"] > .controls > .button.webcam.on {
    background-image: url("./icons/cam_on.svg");
}
[data-component="Tabs"] > .controls > .button.webcam.off {
    background-image: url("./icons/cam_off.svg");
}
[data-component="Tabs"] > .controls > .button.share.on {
    background-image: url("./icons/share_on.svg");
}
[data-component="Tabs"] > .controls > .button.share.off {
    background-image: url("./icons/share_off.svg");
}
[data-component="Tabs"] > .controls > .button.disconnect {
    background-image: url("./icons/disconnect.svg");
}
[data-component="Tabs"] > .controls > .button.record.on {
  background-image: url("./icons/record_on.svg");
}
[data-component="Tabs"] > .controls > .button.record.off {
  background-image: url("./icons/record_off.svg");
}
[data-component="Tabs"] > .controls > .button.muteAll {
  background-image: url("./icons/mute_all.svg");
}
[data-component="Tabs"] > .controls > .button.attendence {
  background-image: url("./icons/attendence.svg");
}
[data-component="Tabs"] > .controls > .button.handraise {
  background-image: url("./icons/hand_raise.svg");
}
[data-component="Tabs"] > .controls > .button.understood {
  background-image: url("./icons/like.svg");
}
[data-component="Tabs"] > .controls > .button.notUnderstood {
  background-image: url("./icons/dislike.svg");
}