[data-component='Peers'] {
  min-height: 100%;
  width: 100%;
}
@media (min-device-width: 721px) {
  [data-component='Peers'] {
    width: 75%;
    padding: 40px 10px 100px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
@media (max-device-width: 720px) {
  [data-component='Peers'] {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
[data-component='Peers'] > .peer-container {
  overflow: hidden;
}
@media (min-device-width: 721px) {
  [data-component='Peers'] > .peer-container {
    flex: 0 0 auto;
    height: 382px;
    width: 450px;
    margin: 6px;
    border: 1px solid rgba(255,255,255,0.15);
    box-shadow: 0px 5px 12px 2px rgba(17,17,17,0.5);
    transition-property: border-color;
    transition-duration: 0.35s;
    border-radius: 15px;
  }
  [data-component='Peers'] > .teacher {
    flex: 0 0 auto;
    height: 70%;
    width: 95%; 
    margin: 6px;
    border: 1px solid rgba(255,255,255,0.15);
    box-shadow: 0px 5px 12px 2px rgba(17,17,17,0.5);
    transition-property: border-color;
    transition-duration: 0.35s;
    border-radius: 15px;
  }
  [data-component='Peers'] > .peer-container.active-speaker {
    border-color: #fff;
  }
}
@media (max-device-width: 720px) {
  [data-component='Peers'] > .peer-container {
    flex: 100 100 auto;
    order: 2;
    min-height: 25vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  [data-component='Peers'] > .peer-container.active-speaker {
    order: 1;
  }
}
