html {
  height: 100%;
  box-sizing: border-box;
  font-family: 'Roboto';
  font-weight: 300;
}
@media (min-device-width: 721px) {
  html {
    font-size: 16px;
  }
}
@media (max-device-width: 720px) {
  html {
    font-size: 12px;
  }
}
body {
  height: 100%;
}
#mediasoup-demo-app-container {
  height: 100%;
  width: 100%;
}
#mediasoup-demo-app-media-query-detector {
  position: relative;
  z-index: -1000;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 1px;
}
@media (max-device-width: 720px) {
  #mediasoup-demo-app-media-query-detector {
    display: none;
    position: fixed;
  }
}