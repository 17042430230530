#classVideo{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

video{
    pointer-events: none;
}

.messageName{
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 400;
    text-align: start;
    padding: 0 10px;
}

.response{
    font-size: 15px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 200;
    text-align: end;
    padding: 0 10px;
}

.message-private{
    width: 100%;
    text-align: start;
    padding: 5px;
}

.privateName{
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: start;
    width: 30%;
}

.privateNameYou{
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: start;
    width: 30%;
    font-style: italic;
}

.privateMessage{
    font-size: 16px;
    font-family: sans-serif;
    text-align: start;
    width: 70%;
}

.replyButtonStyle{
    padding: 5px 5px 5px 15px;
    width: 20%;
}

.yourOwnMessage{
    width: 100%;
    text-align:start;
    padding: 6px;
}

.message-everyone{
    width: 100%;
    text-align: start;
}

.public{
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    text-align:start;
}

.publicMessage{
    font-size: 16px;
    text-align:start;
    font-family: sans-serif;
}

.attendeeStyle{
    font-size: 18px;
    padding: 5px;
    text-align: start;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

::-webkit-scrollbar {
   width: 2px;
height: 2px;
}

.space{
    display: flex;
    justify-content: space-between;
}
.public ,
.publicMessage,
.privateName,
.privateMessage  {
    color: white;
    font-family: montserrat;
     
}
.publicMessage{
text-transform: uppercase;
}

.yourOwnMessage{
    background-color:rgb(89, 89, 207);
    border-radius: 30px;
    height:40px;
    text-align: left;
}

.message-private{
    background-color:rgb(89, 89, 207);
    border-radius: 30px;
    height:40px;
    text-align: right;
}

.AttendanceName{
    text-transform: uppercase;
    font-family: montserrat;
}

.messageName{
    text-transform: uppercase;
    font-family: montserrat;
}
.privateNameYou ,
.privateMessage,
.privateName,
.privateMessage  {
    color: white;
    font-family: montserrat;
     
}
.publicMessage{
text-transform: uppercase;
}

.yourOwnMessage{
    background-color:rgb(89, 89, 207);
    border-radius: 30px;
    height:40px;
    text-align: left;
}
.message-private{
    background-color:rgb(6, 6, 70);
    border-radius: 30px;
    height:40px;
    text-align: right;
}

.message-everyone{
    background-color:rgb(89, 89, 207);
    border-radius: 30px;
    height:40px;
    text-align: right;
}