[data-component="Room"] {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
[data-component="Room"] > .state {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #0B0F15;
}
@media (min-device-width: 721px) {
  [data-component="Room"] > .state {
    top: 20px;
    left: 20px;
    width: 124px;
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .state {
    top: 10px;
    left: 10px;
    width: 110px;
  }

  /* [data-component="Room"] {
    display: grid;
  } */
}
[data-component="Room"] > .state > .icon {
  flex: 0 0 auto;
  border-radius: 100%;
}
@media (min-device-width: 721px) {
  [data-component="Room"] > .state > .icon {
    margin: 6px;
    margin-right: 0;
    height: 16px;
    width: 16px;
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .state > .icon {
    margin: 4px;
    margin-right: 0;
    height: 16px;
    width: 16px;
  }
}
[data-component="Room"] > .state > .icon.new,
[data-component="Room"] > .state > .icon.closed {
  background-color: rgba(170, 170, 170, 0.5);
}
[data-component="Room"] > .state > .icon.connecting {
  animation: Room-info-state-connecting 0.75s infinite linear;
}
[data-component="Room"] > .state > .icon.connected {
  background-color: rgba(48, 189, 24, 0.75);
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .state > .icon.connected {
    display: none;
  }
}
[data-component="Room"] > .state > .text {
  flex: 100 0 auto;
  user-select: none;
  pointer-events: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
}
@media (min-device-width: 721px) {
  [data-component="Room"] > .state > .text {
    font-size: 12px;
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .state > .text {
    font-size: 10px;
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .state > .text.connected {
    display: none;
  }
}
[data-component="Room"] > .room-link-wrapper {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
[data-component="Room"] > .room-link-wrapper > .room-link {
  width: auto;
  background-color: rgba(36, 59, 85, 0.75);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0px 3px 12px 2px rgba(17, 17, 17, 0.4);
}
[data-component="Room"] > .room-link-wrapper > .room-link > a.link {
  display: block;
  user-select: none;
  pointer-events: auto;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  text-decoration: none;
  transition-property: color;
  transition-duration: 0.1s;
}
@media (min-device-width: 721px) {
  [data-component="Room"] > .room-link-wrapper > .room-link > a.link {
    padding: 10px 20px;
    font-size: 12px;
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .room-link-wrapper > .room-link > a.link {
    padding: 6px 10px;
    font-size: 10px;
  }
}
[data-component="Room"] > .room-link-wrapper > .room-link > a.link:hover {
  color: #fff;
  text-decoration: underline;
}
[data-component="Room"] > .me-container {
  position: fixed;
  z-index: 100;
  overflow: hidden;
  box-shadow: 0px 5px 12px 2px rgba(17, 17, 17, 0.5);
  transition-property: border-color;
  transition-duration: 0.2s;
}
[data-component="Room"] > .me-container.active-speaker {
  border-color: #fff;
}
@media (min-device-width: 769px) {
  [data-component="Room"] > .me-container {
    height: 190px;
    width: 180px;
    bottom: 3%;
    left: 20px;
    border: 2px solid rgba(255, 255, 255, 0.15);
    border-radius: 15px;
  }
}
@media (max-device-width: 768px) {
  [data-component="Room"] > .me-container {
    height: 145px;
    width: 135px;
    bottom: 3%;
    left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    top: 2%;
  }
}
@media (max-device-width: 480px) {
  [data-component="Room"] > .me-container {
    height: 145px;
    width: 135px;
    bottom: 3%;
    left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    top: 2%;
  }
}
@media (max-device-width: 360px){
  [data-component="Room"] > .me-container {
    height: 145px;
    width: 135px;
    bottom: 3%;
    left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    top: 2%;
  }
}
[data-component="Room"] > .chat-input-container {
  position: fixed;
  z-index: 100;
  overflow: hidden;
  box-shadow: 0px 5px 12px 2px rgba(17, 17, 17, 0.5);
}
@media (min-device-width: 721px) {
  [data-component="Room"] > .chat-input-container {
    height: 30px;
    width: 294px;
    bottom: 60px;
    left: 20px;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .chat-input-container {
    height: 30px;
    width: 200px;
    bottom: 83px;
    left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
}
[data-component="Room"] > .sidebar {
  position: fixed;
  z-index: 101;
  top: calc(50% - 60px);
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-device-width: 721px) {
  [data-component="Room"] > .sidebar {
    left: 20px;
    width: 36px;
    margin-top: -60px;
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .sidebar {
    left: 10px;
    width: 32px;
    margin-top: -80px;
  }
}
[data-component="Room"] > .sidebar > .button {
  flex: 0 0 auto;
  margin: 4px 0;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  background-color: rgba(36, 59, 85, 0.75);
  cursor: pointer;
  transition-property: opacity, background-color;
  transition-duration: 0.2s;
  border-radius: 100%;
}
@media (min-device-width: 721px) {
  [data-component="Room"] > .sidebar > .button {
    height: 36px;
    width: 36px;
  }
}
@media (max-device-width: 720px) {
  [data-component="Room"] > .sidebar > .button {
    height: 32px;
    width: 32px;
  }
}
[data-component="Room"] > .sidebar > .button.on {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-component="Room"] > .sidebar > .button.disabled {
  pointer-events: none;
  opacity: 0.5;
  transition-duration: 0s;
}
[data-component="Room"] > .sidebar > .button.hide-videos {
  background-image: url("./resources/images/icon_video_white_on.svg");
}
[data-component="Room"] > .sidebar > .button.hide-videos.on {
  background-image: url("./resources/images/icon_video_black_off.svg");
}
[data-component="Room"] > .sidebar > .button.mute-audio {
  background-image: url("./resources/images/icon_volume_white_on.svg");
}
[data-component="Room"] > .sidebar > .button.mute-audio.on {
  background-image: url("./resources/images/icon_volume_black_off.svg");
}
[data-component="Room"] > .sidebar > .button.restart-ice {
  background-image: url("./resources/images/icon_restart_ice_white.svg");
}
@-moz-keyframes Room-info-state-connecting {
  50% {
    background-color: rgba(255, 165, 0, 0.75);
  }
}
@-webkit-keyframes Room-info-state-connecting {
  50% {
    background-color: rgba(255, 165, 0, 0.75);
  }
}
@-o-keyframes Room-info-state-connecting {
  50% {
    background-color: rgba(255, 165, 0, 0.75);
  }
}
@keyframes Room-info-state-connecting {
  50% {
    background-color: rgba(255, 165, 0, 0.75);
  }
}
