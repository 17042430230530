[data-component='PeerView'] {
  position: relative;
  flex: 100 100 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #1E232F;
  background-position: center;
  background-size: auto 65%;
  background-repeat: no-repeat;
  border-radius: 15px;
}
[data-component='PeerView'] > .info {
  position: absolute;
  pointer-events: none;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
[data-component='PeerView'] > .info > .icons {
  display: flex;
  flex-direction: row;
}
[data-component='PeerView'] > .info > .icons > .icon {
  pointer-events: auto;
  margin: 4px;
  margin-right: 0;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition-property: opacity, background-color;
  transition-duration: 0.15s;
}
@media (min-device-width: 721px) {
  [data-component='PeerView'] > .info > .icons > .icon {
    width: 28px;
    height: 28px;
    opacity: 0.75;
  }
  [data-component='PeerView'] > .info > .icons > .icon:hover {
    opacity: 1;
  }
}
@media (max-device-width: 720px) {
  [data-component='PeerView'] > .info > .icons > .icon {
    width: 26px;
    height: 26px;
  }
}
[data-component='PeerView'] > .info > .icons > .icon.info.on {
  background-image: url("./resources/images/icon_info_white_on.svg");
}
[data-component='PeerView'] > .info > .icons > .icon.info:not(.on) {
  background-image: url("./resources/images/icon_info_white_off.svg");
}
[data-component='PeerView'] > .info > .icons > .icon.stats {
  background-image: url("./resources/images/icon_stats_white_on.svg");
}
[data-component='PeerView'] > .info > .box {
  pointer-events: auto;
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 4px;
  padding: 4px 6px 50px 6px;
  border-radius: 2px;
  background-color: rgba(0,0,0,0.65);
  opacity: 1;
  overflow: auto;
  transform-origin: left top;
  transition: 0.1s;
}
@media (min-device-width: 721px) {
  [data-component='PeerView'] > .info > .box {
    top: 36px;
  }
}
@media (max-device-width: 720px) {
  [data-component='PeerView'] > .info > .box {
    top: 34px;
  }
}
[data-component='PeerView'] > .info > .box:not(.visible) {
  z-index: -1;
  opacity: 0;
  transform: scale(0);
}
[data-component='PeerView'] > .info > .box > h1 {
  pointer-events: none;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
[data-component='PeerView'] > .info > .box > p {
  pointer-events: none;
  margin-bottom: 2px;
  color: rgba(255,255,255,0.75);
  font-size: 11px;
}
[data-component='PeerView'] > .info > .box > p:last-child {
  margin-bottom: 0;
}
[data-component='PeerView'] > .info > .box > p.clickable,
[data-component='PeerView'] > .info > .box > p .clickable {
  pointer-events: auto;
  user-select: none;
  color: #44c5d6;
}
[data-component='PeerView'] > .info > .box > p.clickable:hover,
[data-component='PeerView'] > .info > .box > p .clickable:hover {
  cursor: pointer;
  color: #73feff;
  text-decoration: underline;
}
[data-component='PeerView'] > .info > .box > p.copiable,
[data-component='PeerView'] > .info > .box > p .copiable {
  pointer-events: auto;
  cursor: text;
}
[data-component='PeerView'] > .info > .box > p.copiable:hover,
[data-component='PeerView'] > .info > .box > p .copiable:hover {
  color: #44c5d6;
  text-decoration: underline;
}
[data-component='PeerView'] > .info > .box > p.indent {
  margin-left: 10px;
}
[data-component='PeerView'] > .info > .peer {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (min-device-width: 721px) {
  [data-component='PeerView'] > .info > .peer.is-me {
    padding: 10px;
    align-items: flex-start;
  }
  [data-component='PeerView'] > .info > .peer:not(.is-me) {
    padding: 20px;
    align-items: flex-start;
    pointer-events: none;
  }
}
@media (max-device-width: 720px) {
  [data-component='PeerView'] > .info > .peer.is-me {
    padding: 10px;
    align-items: flex-start;
  }
  [data-component='PeerView'] > .info > .peer:not(.is-me) {
    padding: 10px;
    align-items: flex-end;
  }
}
[data-component='PeerView'] > .info > .peer > .display-name {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255,255,255,0.85);
}
[data-component='PeerView'] > .info > .peer > span.display-name {
  user-select: none;
  cursor: text;
}
[data-component='PeerView'] > .info > .peer > span.display-name:not(.editable) {
  cursor: default;
}
[data-component='PeerView'] > .info > .peer > span.display-name.editable {
  pointer-events: auto;
}
@media (min-device-width: 721px) {
  [data-component='PeerView'] > .info > .peer > span.display-name.editable:hover {
    background-color: rgba(174,255,0,0.25);
  }
}
[data-component='PeerView'] > .info > .peer > span.display-name.loading {
  opacity: 0.5;
}
[data-component='PeerView'] > .info > .peer > input.display-name {
  border: none;
  border-bottom: 1px solid #aeff00;
  background-color: transparent;
}
[data-component='PeerView'] > .info > .peer > .row {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
[data-component='PeerView'] > .info > .peer > .row > .device-icon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  user-select: none;
  pointer-events: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./resources/images/devices/unknown.svg");
}
[data-component='PeerView'] > .info > .peer > .row > .device-icon.chrome {
  background-image: url("./resources/images/devices/chrome_16x16.png");
}
[data-component='PeerView'] > .info > .peer > .row > .device-icon.firefox {
  background-image: url("./resources/images/devices/firefox_16x16.png");
}
[data-component='PeerView'] > .info > .peer > .row > .device-icon.safari {
  background-image: url("./resources/images/devices/safari_16x16.png");
}
[data-component='PeerView'] > .info > .peer > .row > .device-icon.msedge {
  background-image: url("./resources/images/devices/edge_16x16.png");
}
[data-component='PeerView'] > .info > .peer > .row > .device-icon.opera {
  background-image: url("./resources/images/devices/opera_16x16.png");
}
[data-component='PeerView'] > .info > .peer > .row > .device-icon.broadcaster {
  height: 18px;
  width: 18px;
  background-image: url("./resources/images/devices/broadcaster.svg");
}
[data-component='PeerView'] > .info > .peer > .row > .device-version {
  user-select: none;
  pointer-events: none;
  font-size: 11px;
  color: rgba(255,255,255,0.55);
}
[data-component='PeerView'] > video {
  flex: 100 100 auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
  user-select: none;
  transition-property: opacity;
  transition-duration: 0.5s;
  background-color: rgba(0,0,0,0.75);
}
[data-component='PeerView'] > video.is-me {
  transform: scaleX(-1);
}
[data-component='PeerView'] > video.hidden {
  opacity: 0;
  transition-duration: 0s;
}
[data-component='PeerView'] > video.network-error {
  filter: grayscale(100%) brightness(135%) blur(5px);
}
[data-component='PeerView'] > audio {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  border: 2px solid #f00;
}
[data-component='PeerView'] > canvas.face-detection {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
[data-component='PeerView'] > canvas.face-detection.is-me {
  transform: scaleX(-1);
}
[data-component='PeerView'] > .volume-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2px;
  width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
[data-component='PeerView'] > .volume-container > .bar {
  width: 6px;
  border-radius: 6px;
  background: rgba(255,255,0,0.65);
  transition-property: height background-color;
  transition-duration: 0.25s;
}
[data-component='PeerView'] > .volume-container > .bar.level0 {
  height: 0;
  background-color: rgba(255,255,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level1 {
  height: 10%;
  background-color: rgba(255,255,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level2 {
  height: 20%;
  background-color: rgba(255,255,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level3 {
  height: 30%;
  background-color: rgba(255,255,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level4 {
  height: 40%;
  background-color: rgba(255,165,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level5 {
  height: 50%;
  background-color: rgba(255,165,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level6 {
  height: 60%;
  background-color: rgba(255,0,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level7 {
  height: 70%;
  background-color: rgba(255,0,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level8 {
  height: 80%;
  background-color: rgba(0,0,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level9 {
  height: 90%;
  background-color: rgba(0,0,0,0.65);
}
[data-component='PeerView'] > .volume-container > .bar.level10 {
  height: 100%;
  background-color: rgba(0,0,0,0.65);
}
[data-component='PeerView'] > .spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(0,0,0,0.75);
}
[data-component='PeerView'] > .spinner-container .react-spinner {
  position: relative;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
}
[data-component='PeerView'] > .spinner-container .react-spinner .react-spinner_bar {
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
  animation: PeerView-spinner 1.2s linear infinite;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.5);
}
[data-component='PeerView'] > .video-elem-paused {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-position: center;
  background-image: url("./resources/images/icon_video_elem_paused.svg");
  background-size: 35%;
  background-color: rgba(0,0,0,0.25);
  background-repeat: no-repeat;
}
@-moz-keyframes PeerView-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@-webkit-keyframes PeerView-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@-o-keyframes PeerView-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@keyframes PeerView-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
